/* Navbar styling */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.navbar-title {
  font-size: 2rem;
  font-weight: bold;
}

.navbar-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-button {
  font-size: 1.2rem;
  padding: 8px 16px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;
  color: white; /* Set text color to white */
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-link {
  text-decoration: none;
  color: inherit; /* Inherit the color from parent */
}

.nav-link.active {
  border-bottom: 2px solid yellow; /* Active state with yellow bottom border */
}

.drawer-menu {
  width: 250px;
  padding: 20px;
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
}

.menu-icon {
  display: none;
}

@media (max-width: 600px) {
  .navbar-buttons {
    display: none;
  }
  .menu-icon {
    display: block;
  }
}
